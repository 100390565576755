import { useEffect, useRef, useState, useContext} from "react";
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import API from '../../utils/API';
import './style.css';
import { AuthContext } from '../../components/AuthContext/AuthContext';
import FinalScore from "../../components/FinalScore";
import HostQuizReport from "../../components/QuizReport/HostQuizReport";
import basketballPreview from '../../assets/images/basketball.PNG'
import MildlyInfuriatedBirdPreview from '../../assets/images/MildlyInfuriatedBird.png'
import HungryHeroPreview from '../../assets/images/HungryHero.png'
import HungryHero2Preview from '../../assets/images/HungryHero2.png'
import HeroOfDawnPreview from '../../assets/images/PreviewFolder/HeroOfDawnPreview.png'
import koobloClassicPreview from '../../assets/images/kooblo-classic-preview.png'

const gameData = {
    "MildlyInfuriatedBird": {
        name: 'Mildly Infuriated Bird',
        image: MildlyInfuriatedBirdPreview
    },
    "basketball": {
        name: 'Trivia Dunk',
        image: basketballPreview
    },
    "hungryhero": {
        name: 'Hungry Hero',
        image: HungryHeroPreview
    },
    "hungryheroremix": {
        name: 'Hungry Hero: Remix',
        image: HungryHero2Preview
    },
    "hero-of-dawn": {
        name: 'Hero Of Dawn',
        image: HeroOfDawnPreview
    },
    "kooblo-classic": {
        name: 'Kooblo Classic',
        image: koobloClassicPreview
    }

};

const GameHistory = () => {
    const { user } = useContext(AuthContext);
    const [gameHistory, setGameHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [limit] = useState(5); // Number of items per page
    const [selectedGame, setSelectedGame] = useState(null);

    // Function to fetch game history data
    const fetchGameHistory = async (page = 1) => {
        setLoading(true);
        const offset = (page - 1) * limit;

        API.gameHistory({ limit, offset })
            .then(res => {
                setGameHistory(res.data.data);
                setTotalPages(res.data.totalPages);
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    };

    // Fetch data when component mounts or page changes
    useEffect(() => {
        fetchGameHistory(currentPage);
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const onGameHistoryClick = (game) => {
        const userAvatars = {};
        const updatedPlayers = game.players.map(player => {
            const avatarUrl = `https://api.dicebear.com/7.x/big-smile/svg?seed=${player.username}`;
            userAvatars[player.username] = avatarUrl;
            return { ...player, avatar: avatarUrl };
        });
    
        const updatedGame = { 
            ...game, 
            players: updatedPlayers,
            userAvatars 
        };
    
        setSelectedGame(updatedGame);

    };
            
    return (
        <Container fluid>
            <Container>
                <Row>
                    <Col xs={2} className="d-flex align-items-center">
                        {/* Back to History Button outside Card */}
                        {selectedGame && (
                            <button 
                                style={{ borderRadius: "25px" }} 
                                className="btn nav-btn-light-purple navbar-btn d-flex justify-content-center align-items-center"
                                onClick={() => setSelectedGame(null)}
                            >
                                <i className="fa-solid fa-arrow-left"></i> {/* Icon will be shown on all screens */}
                                <span className="d-none d-sm-inline"> Back</span> {/* Text will be hidden on small screens */}
                            </button>
                        )}
                    </Col>
                    <Col xs={8} className="text-center titan-one-regular">
                        <h1 className="h1-responsive">Game History</h1>
                        {loading && <p>Loading...</p>}
                    </Col>
                </Row>
            </Container>


            {selectedGame ? (
                // Show selected game details
                <>
                    <FinalScore
                        users={selectedGame.players}
                        userAvatar={selectedGame.userAvatars}
                        roomId={selectedGame.roomId}
                    />
                    <HostQuizReport 
                        quizData={selectedGame.players} 
                        isSubscribed={user ? user.isSubscribed : false} 
                    />
                    {/* <button onClick={() => setSelectedGame(null)}>Back to History</button> */}
                </>
            ) : (
                // Show game history list and pagination if no game is selected
                <>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10} sm={12}>
                            {gameHistory.length > 0 ? (
                                <div className="game-history-list">
                                    {gameHistory.map((game, index) => {
                                        const formattedDate = new Date(game.createdAt).toLocaleString(undefined, {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: true,
                                        });
                                        const gameInfo = gameData[game.gameType] || gameData[game.gameType.toLowerCase()] || { name: "Unknown Game", image: null };


                                        return (
                                            <Card 
                                                key={index} 
                                                className="mb-3 shadow-sm game-card"
                                                onClick={() => onGameHistoryClick(game)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <CardBody>
                                                    <Row className="align-items-center">
                                                        {/* Left Side: Game Details */}
                                                        <Col xs={12} md={8}>
                                                            <h5 className="card-title">
                                                                <strong>Game:</strong> {gameInfo.name}
                                                            </h5>
                                                            <p className="card-text">
                                                                <strong>Question Set:</strong> {game.questionSet.title}
                                                            </p>
                                                            <p className="card-text">
                                                                <strong>Players:</strong> {game.players.length}
                                                            </p>
                                                            <p className="card-text">
                                                                <strong>Played On:</strong> {formattedDate}
                                                            </p>
                                                        </Col>

                                                        {/* Right Side: Game Image (Responsive) */}
                                                        {gameInfo.image && (
                                                            <Col xs={12} md={4} className=" text-center">
                                                                <img 
                                                                    src={gameInfo.image} 
                                                                    alt={gameInfo.name} 
                                                                    className="game-preview-img"
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </CardBody>
                                            </Card>

                                        );
                                    })}
                                </div>
                            ) : (
                                <p className="text-center">No game history available.</p>
                            )}
                        </Col>
                    </Row>

                    {/* Pagination Controls */}
                    <Row className="justify-content-center mt-4">
                        <Col md="auto">
                            <div className="pagination-container text-center mt-4">
                                {currentPage > 1 && (
                                    <button 
                                        style={{ borderRadius: "25px" }} 
                                        className="btn nav-btn-light-purple navbar-btn" 
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        <i className="fa-solid fa-arrow-left"></i> Previous
                                    </button>
                                )}
                                {totalPages > 1 && (
                                    Array.from({ length: totalPages }, (_, index) => (
                                        <button 
                                            key={index} 
                                            onClick={() => handlePageChange(index + 1)} 
                                            disabled={currentPage === index + 1} 
                                            className="pagination-button titan-one-small"
                                        >
                                            {index + 1}
                                        </button>
                                    ))
                                )}
                                {currentPage < totalPages && (
                                    <button 
                                        style={{ borderRadius: "25px" }} 
                                        className="btn nav-btn-light-purple navbar-btn" 
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default GameHistory;
