import React, { useEffect,useState, useRef } from 'react';
import './style.css';
import API from '../../utils/API'
import FinalScoreWaiting from "../../components/FinalScoreWaiting";
import MultiplierBorder from './MultiplierBorder';
import QuizContainer from './QuizContainer';
import clickSoundFile from './sounds/click-sound.wav';
import wrongClickSoundFile from './sounds/bubble-click.mp3'
import howYouRollSoundFile from './sounds/how-you-roll-neozoic.mp3';


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

const KoobloClassic = (props) => {
      const { handleScoreChange,isGameOver,getReport } = props; // Destructure handleScoreChange from props
      const [gameStart, setGameStart] = useState(false);
      const [score, setScore] = useState(0); // React state for score
      const [questionSet, setQuestionSet] = useState({});
      const[questionsPool,setQuestionsPool] = useState([]);
      const[nextQuestion,setNextQuestion] = useState(null);
      const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
      const [quizReport, setQuizReport] = useState({
        questions: [],       // Array to store questions and their data
        totalCorrect: 0,     // Counter for correct answers
        questionsAnswered: 0 // Counter for total questions answered
      });
      const [multiplier,setMultiplier] = useState(1);
      const [frenzy,setFrenzy]=useState(false);
      const[storeMultiplier,setStoreMultiplier]=useState(1)
      const clickSoundRef = useRef(new Audio(clickSoundFile));
      const wrongClickSoundRef = useRef(new Audio(wrongClickSoundFile));
      const backgroundMusicRef = useRef(null);
      const [isMuted, setIsMuted] = useState(false);
      const frenzyTimeoutRef = useRef(null);

      useEffect(() => {
        if (!props.socket) return; // Ensure socket exists
      
        const handleFrenzyStart = () => setFrenzy(true);
        const handleFrenzyEnd = () => setFrenzy(false);
      
        props.socket.on("frenzyStart", handleFrenzyStart);
        props.socket.on("frenzyEnd", handleFrenzyEnd);
      
        return () => {
          props.socket.off("frenzyStart", handleFrenzyStart);
          props.socket.off("frenzyEnd", handleFrenzyEnd);
        };
      }, [props.socket]);
      
      // Set initial volume to 0.5
    // Set initial volume inside useEffect
    useEffect(() => {
      if (clickSoundRef.current) clickSoundRef.current.volume = 0.5;
      if (wrongClickSoundRef.current) wrongClickSoundRef.current.volume = 0.5;
    }, []); // Runs once on mount
      // Function to toggle mute
      const toggleMute = () => {
        setIsMuted((prev) => !prev);
      };
      
      // Update all sound volumes when `isMuted` changes
      useEffect(() => {
        if (backgroundMusicRef.current) {
          backgroundMusicRef.current.volume = isMuted ? 0 : 0.5;
          backgroundMusicRef.current.muted = isMuted;
        }
        clickSoundRef.current.volume = isMuted ? 0 : 0.5;
        wrongClickSoundRef.current.volume = isMuted ? 0 : 0.5;
        clickSoundRef.current.muted = isMuted;
        wrongClickSoundRef.current.muted = isMuted;
        const handleVisibilityChange = () => {
          if (document.hidden) {
            // Pause music when the page is hidden (e.g., phone lock, switching apps)
            if (backgroundMusicRef.current) {
              backgroundMusicRef.current.pause();
            }
          } else {
            // Resume music when the page becomes visible again (e.g., unlock)
            if (backgroundMusicRef.current && !isMuted) {
              backgroundMusicRef.current.play();
            }
          }
        };
      
        document.addEventListener("visibilitychange", handleVisibilityChange);
      
        // Cleanup the event listener when the component is unmounted
        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
      
      }, [isMuted]);
      
      useEffect(() => {
        backgroundMusicRef.current = new Audio(howYouRollSoundFile);
        backgroundMusicRef.current.loop = true;
        backgroundMusicRef.current.volume = 0.5; // Set initial volume
      }, []);
      
      const startGame = () => {
        setGameStart(true);
        backgroundMusicRef.current
          .play()
          .catch((error) => console.error("Error playing background music:", error));
      };
      
      const handleClick = () => {
        clickSoundRef.current.currentTime = 0; // Rewind to the start
        clickSoundRef.current.play().catch((error) => {
          console.error("Error playing click sound:", error);
        });
      };
      
      const handleWrongClick = () => {
        wrongClickSoundRef.current.currentTime = 0; // Rewind to the start
        wrongClickSoundRef.current.play().catch((error) => {
          console.error("Error playing wrong click sound:", error);
        });
      };
            useEffect(()=>{
        if(frenzy){
          setStoreMultiplier(multiplier);
          setMultiplier(6);
        } else {
          setMultiplier(storeMultiplier);
        }
      },[frenzy])

        useEffect(()=>{
          if(props.questionSet){
            setQuestionSet(props.questionSet);
            const shuffledQuestions = shuffleArray(props.questionSet.Questions); // Use slice to avoid mutating the original array
            let cleanedQuestions = []
            for (let i = 0; i < shuffledQuestions.length; i++) {
              if(shuffledQuestions[i].question){
                cleanedQuestions.push(shuffledQuestions[i])
              } 
            }
            setQuestionsPool(cleanedQuestions)
            const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
            // Set the selected question
            setNextQuestion(cleanedQuestions[randomIndex]);
            API.addPlayCount(props.questionSet.id)
          }else{
            
              API.getQuestionSet(10).then(res=>{
                setQuestionSet(res.data);
                const shuffledQuestions = shuffleArray(res.data.Questions); // Use slice to avoid mutating the original array
                let cleanedQuestions = []
                for (let i = 0; i < shuffledQuestions.length; i++) {
                  if(shuffledQuestions[i].question){
                    cleanedQuestions.push(shuffledQuestions[i])
                  } 
                }
                setQuestionsPool(cleanedQuestions)
                const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
                // Set the selected question
                setNextQuestion(cleanedQuestions[randomIndex]);
              }).catch(err=>{
                console.log(err)
            })
          }
        },[])
      
        const handleAnswer = (answer) => {
          if (answer === 'true') {
              // Handle correct answer logic if needed
          }
      
          setAnsweredQuestions(prev => {
              const newAnsweredQuestions = new Set(prev);
              newAnsweredQuestions.add(nextQuestion.id);
      
              // If all questions have been answered, reset answeredQuestions
              if (newAnsweredQuestions.size === questionsPool.length) {
                  setAnsweredQuestions(new Set()); // Reset answered questions
      
                  // Ensure the next question is different from the last one
                  const filteredQuestions = questionsPool.filter(q => q.id !== nextQuestion.id);
                  setNextQuestion(filteredQuestions[Math.floor(Math.random() * filteredQuestions.length)]);
                  
                  return new Set(); // Ensure it resets
              }
      
              // Otherwise, update answeredQuestions and select the next question
              setNextQuestion(selectNextQuestion(newAnsweredQuestions));
              return newAnsweredQuestions;
          });
      };
      
      const selectNextQuestion = (updatedAnsweredQuestions) => {

          const unansweredQuestions = questionsPool.filter(
              question => !updatedAnsweredQuestions.has(question.id)
          );
          if (unansweredQuestions.length === 0) {
              return null; // This should never be reached due to the reset logic
          }
      
          return unansweredQuestions[Math.floor(Math.random() * unansweredQuestions.length)];
      };
      
        const recordAnswer = (answer) => {
        setQuizReport((prev) => {
          // Add the question and selected answer to the questions array
          const updatedQuestions = [
            ...prev.questions,
            { ...nextQuestion, selectedAnswer: answer }
          ];
      
          // Update totalCorrect if the answer is correct
          const isCorrect = answer.correctAnswer=== "true";
          return {
            ...prev,
            questions: updatedQuestions,
            questionsAnswered: prev.questionsAnswered + 1,
            totalCorrect: isCorrect ? prev.totalCorrect + 1 : prev.totalCorrect
          };
        });
      };

      // const scheduleNextFrenzy = () => {
      //   const nextFrenzyTime = Math.random() * (90000 - 30000) + 30000; // 30s - 90s
      //   frenzyTimeoutRef.current = setTimeout(() => {
      //     setFrenzy(true);
      //     setTimeout(() => {
      //       setFrenzy(false);
      //       scheduleNextFrenzy(); // Restart frenzy cycle
      //     }, 20000); // Frenzy lasts 20s
      //   }, nextFrenzyTime);
      // };
      
      // useEffect(() => {
      //   scheduleNextFrenzy(); // Start first frenzy
      //   return () => clearTimeout(frenzyTimeoutRef.current); // Cleanup on unmount
      // }, []);
            
      const updateScore=(newScore)=>{
        setScore(newScore)
        if(handleScoreChange){
          handleScoreChange(newScore);
        }
      }
        useEffect(() => {
          if (isGameOver) {
            handleScoreChange(score);
            getReport(quizReport); 
            // Stop all sounds
            if (backgroundMusicRef.current) {
              backgroundMusicRef.current.pause();
              backgroundMusicRef.current.currentTime = 0;
            }
            if (clickSoundRef.current) {
              clickSoundRef.current.pause();
              clickSoundRef.current.currentTime = 0;
            }
            if (wrongClickSoundRef.current) {
              wrongClickSoundRef.current.pause();
              wrongClickSoundRef.current.currentTime = 0;
            }
        
            // Clear frenzy mode timeout
            clearTimeout(frenzyTimeoutRef.current);
          
          }
        }, [isGameOver]);
      
  return (
    <>
    {
      isGameOver? 
      <div className="vertical-center waiting-room" style={{minHeight:"100vh"}}>
      <FinalScoreWaiting></FinalScoreWaiting>
      </div>
      :(!gameStart ? (
        <div className="start-screen" onClick={startGame}>
          <h1 className="game-title">Kooblo Classic</h1>
          <p className="start-text">Click to Start Game</p>
        </div>
      ) : !nextQuestion ? (
        <p>WAITING</p>
      ) : (
        <MultiplierBorder multiplier={multiplier}>
          <QuizContainer
            onAnswer={handleAnswer}
            score={score}
            updateScore={updateScore}
            question={nextQuestion}
            recordAnswer={recordAnswer}
            multiplier={multiplier}
            setMultiplier={setMultiplier}
            frenzy={frenzy}
            handleClickSound={handleClick}
            handleWrongClickSound={handleWrongClick}
            isMuted={isMuted}         // Pass mute state
            toggleMute={toggleMute}   // Pass function to toggle mute          
          />
        </MultiplierBorder>
      ))
    }
      
    </>
  );
  
};

export default KoobloClassic;
