import { useState, useContext,useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Input, Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, Button } from 'reactstrap';
import './style.css';
import KoobloQuestion from "../KoobloQuestion";
import API from "../../utils/API";
const EditKooblo = (props) => {
    const {id} = useParams();
    const [questions, setQuestions] = useState([]);
    const [questionSet, setQuestionSet] = useState([]);

    useEffect(()=>{
      API.getSet(id).then(res=>{
        setQuestions(res.data.Questions)
        setQuestionSet(res.data)
      }).catch(err=>{
        console.log(err)
      })
    },[])
    const onAddQuestionClick = () => {
      const newQuestion = {
        tempId: `temp-${Date.now()}`,
        question: '',
        answer: '',
        QuestionSetId:id
      };
      setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
      // API.editQuestion(newQuestion).then(res=>{
      //   setQuestions(prevQuestions => [...prevQuestions, res.data[0]]);
      // }).catch(err=>{
      //   console.log(err);
      // })
      // Create a new array with the new question added
    };
    const onDeleteClick = (question) => {
      if (question.id) {
        // Delete from the database if it has an official ID
        API.deleteQuestion(question.id)
          .then(response => {
            console.log(response); 
            setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== question.id));
          })
          .catch(error => {
            console.error("Failed to delete question:", error);
          });
      } else if (question.tempId) {
        // Remove from state if it's a temporary question
        setQuestions(prevQuestions => prevQuestions.filter(q => q.tempId !== question.tempId));
      }
    };
  return (
        <Container>
          {questions.map((question, index) => (
                    <Row className="m-3" key={index}>
                    <Col lg="12"className="align-self-center">
                        <KoobloQuestion
                        key={question.id} // Ensure each component has a unique key
                        question={question}
                        onDeleteClick={()=>onDeleteClick(question)}
                        />
                    </Col>
                    </Row>

                ))}

            <Row className="m-3">

                    <Col lg="12"className="align-self-center">
                        <Card className={"kq-card-container m-0 text-center nunito justify-content-center add-class-card nunito "} onClick={onAddQuestionClick} >
                                    <p className="text-center card-title"><i className="fa-solid fa-plus"></i> Add Question</p>
                        </Card>

                    </Col>
            </Row>
        </Container>


  );
};

export default EditKooblo; 