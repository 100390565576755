import React, { useEffect,useState, useRef } from 'react';
import Phaser from 'phaser';
import BasketballGameScene from './BasketballGameScene';
import './style.css';
import QuizModal from '../../components/QuizModal'
import API from '../../utils/API'
import FinalScoreWaiting from "../../components/FinalScoreWaiting";
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

const BasketballGame = (props) => {
  let game;
  const gameContainerRef = useRef(null);
  const { handleScoreChange,isGameOver,getReport } = props; // Destructure handleScoreChange from props
  const [phaserGame, setPhaserGame] = useState(null); // State to store the Phaser game instance
  const [score, setScore] = useState(0); // React state for score
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const[questionsPool,setQuestionsPool] = useState([]);
  const [questionSet, setQuestionSet] = useState({});
  const[nextQuestion,setNextQuestion] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
  const [quizReport, setQuizReport] = useState({
    questions: [],       // Array to store questions and their data
    totalCorrect: 0,     // Counter for correct answers
    questionsAnswered: 0 // Counter for total questions answered
  });

  useEffect(()=>{
    if(isModalOpen){
      if(handleScoreChange){
        handleScoreChange(score);
      }    
    }
  },[isModalOpen])

  useEffect(() => {
    if (isGameOver) {
      handleScoreChange(score);
      getReport(quizReport); 
      handleGameEnd(); // Call the function when isGameOver becomes true
    }
  }, [isGameOver]);

  const handleGameEnd = () => {
    // Implement your game end logic here
    if (phaserGame) {
      // Destroy the Phaser game instance when the game is over
      phaserGame.destroy(true);
      setPhaserGame(null); // Clear the Phaser game instance from the state
    }
    
  };

  const handleAnswer = (answer) => {
    // Resume the game or proceed based on the answer
    if(answer === 'true'){
      setIsModalOpen(false)        
      if (phaserGame) {
        // Access the scene manager and restart the scene

        const gameState = phaserGame.scene.getScene('BasketballGameScene');
        if (gameState) {
          gameState.scene.scene.game.resume();
        }
      }      
    } 
    setAnsweredQuestions(prev => {
      const newAnsweredQuestions = new Set(prev);
      newAnsweredQuestions.add(nextQuestion.id);

      // If all questions have been answered, reset answeredQuestions
      if (newAnsweredQuestions.size === questionsPool.length) {
          setAnsweredQuestions(new Set()); // Reset answered questions

          // Ensure the next question is different from the last one
          const filteredQuestions = questionsPool.filter(q => q.id !== nextQuestion.id);
          setNextQuestion(filteredQuestions[Math.floor(Math.random() * filteredQuestions.length)]);
          
          return new Set(); // Ensure it resets
      }

      // Otherwise, update answeredQuestions and select the next question
      setNextQuestion(selectNextQuestion(newAnsweredQuestions));
      return newAnsweredQuestions;
  });
};
const selectNextQuestion = (updatedAnsweredQuestions) => {

    const unansweredQuestions = questionsPool.filter(
        question => !updatedAnsweredQuestions.has(question.id)
    );
    if (unansweredQuestions.length === 0) {
        return null; // This should never be reached due to the reset logic
    }

    return unansweredQuestions[Math.floor(Math.random() * unansweredQuestions.length)];
};

  useEffect(()=>{
    if(props.questionSet){
      setQuestionSet(props.questionSet);
      const shuffledQuestions = shuffleArray(props.questionSet.Questions); // Use slice to avoid mutating the original array
      let cleanedQuestions = []
      for (let i = 0; i < shuffledQuestions.length; i++) {
        if(shuffledQuestions[i].question){
          cleanedQuestions.push(shuffledQuestions[i])
        } 
      }
      setQuestionsPool(cleanedQuestions)
      const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
      // Set the selected question
      setNextQuestion(cleanedQuestions[randomIndex]);
      API.addPlayCount(props.questionSet.id)
    }else{
      
        API.getQuestionSet(10).then(res=>{
          setQuestionSet(res.data);
          const shuffledQuestions = shuffleArray(res.data.Questions); // Use slice to avoid mutating the original array
          let cleanedQuestions = []
          for (let i = 0; i < shuffledQuestions.length; i++) {
            if(shuffledQuestions[i].question){
              cleanedQuestions.push(shuffledQuestions[i])
            } 
          }
          setQuestionsPool(cleanedQuestions)
          const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
          // Set the selected question
          setNextQuestion(cleanedQuestions[randomIndex]);
        }).catch(err=>{
          console.log(err)
      })
    }
  },[])

  useEffect(() => {
    if (gameContainerRef.current) {
      const config = {
        type: Phaser.AUTO,
        scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
          width: 400,
          height: 625,
            },
        physics: {
          default: 'arcade',
          arcade: {
            gravity: { y: 3000 },
            debug: false
          }
        },
        scene: [BasketballGameScene],
        parent: gameContainerRef.current, // Pass the current DOM element
        input: {
          keyboard: true,
          mouse: true,
          touch: true // Ensure touch input is enabled
        }
      };
      game = new Phaser.Game(config);
      game.reactSetScore = setScore;
      game.reactScore = score;
      game.openQuestion = openQuestion; // Expose modal control to Phaser
      setPhaserGame(game)
  
      return () => {
        if (phaserGame) {
          phaserGame.destroy(true);
          setPhaserGame(null);
        }
      };
      }
  }, []);
  const openQuestion= ()=>{
    setIsModalOpen(true);
  }
  const recordAnswer = (answer) => {
    setQuizReport((prev) => {
      // Add the question and selected answer to the questions array
      const updatedQuestions = [
        ...prev.questions,
        { ...nextQuestion, selectedAnswer: answer }
      ];
  
      // Update totalCorrect if the answer is correct
      const isCorrect = answer.correctAnswer=== "true";
      return {
        ...prev,
        questions: updatedQuestions,
        questionsAnswered: prev.questionsAnswered + 1,
        totalCorrect: isCorrect ? prev.totalCorrect + 1 : prev.totalCorrect
      };
    });
  };
  return (
    <div className="basketball-game-container">
      {/* <span>
        Instructions: Quickly drag the ball to shoot the ball into the hoop!
      </span> */}
      {isGameOver? 
        <div className="vertical-center waiting-room" style={{minHeight:"100vh"}}>
        <FinalScoreWaiting></FinalScoreWaiting>
        </div>
        :
        <>
      <div ref={gameContainerRef} id="basketball-game"></div>
      <QuizModal 
          isOpen={isModalOpen} 
          onRequestClose={() => setIsModalOpen(false)} 
          onAnswer={handleAnswer} 
          question={nextQuestion}
          recordAnswer={recordAnswer}
        />
        </>   
      }

    </div>
  );
};

export default BasketballGame;
