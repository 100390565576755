import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './style.css'; // Assuming you have a separate CSS file for styling

function shuffleObject(obj) {
  const nonEmptyAnswers = Object.values(obj).filter(answer => answer.answer.trim() !== "");
  const emptyAnswers = Object.values(obj).filter(answer => answer.answer.trim() === "");

  for (let i = nonEmptyAnswers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [nonEmptyAnswers[i], nonEmptyAnswers[j]] = [nonEmptyAnswers[j], nonEmptyAnswers[i]];
  }

  const combinedAnswers = [...nonEmptyAnswers, ...emptyAnswers];

  const shuffledObject = {};
  combinedAnswers.forEach((answer, index) => {
    shuffledObject[`answer${index + 1}`] = answer; 
  });

  return shuffledObject;
}
const colors = ["red", "blue", "green", "purple", "orange", "pink"];

const QuizContainer = ({ onAnswer, question , recordAnswer, multiplier, setMultiplier, frenzy, handleClickSound,handleWrongClickSound, isMuted, toggleMute, score,updateScore}) => {
  const [time, setTime] = useState(30)
  const [timeLeft, setTimeLeft] = useState(30); // Using only timeLeft now
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [shuffledAnswers, setShuffledAnswers] = useState(null);
  const [canProceed, setCanProceed] = useState(false);
  const [timer, setTimer] = useState(3);
  const [initialOverlayVisible, setInitialOverlayVisible] = useState(false);
  const [key, setKey] = useState(0); // Unique key to reset animation
  const [stopAnimation, setStopAnimation] = useState(false); // New state to stop animation
  const [muted, setMuted] = useState(false);
  // const [score,setScore]=useState(0);
  const [correctStreak, setCorrectStreak] = useState(0); // Tracks consecutive correct answers
  const [scoreEffect, setScoreEffect] = useState(null);
  const [floatingTexts, setFloatingTexts] = useState([]); // Track floating texts

  const floatingPhrases = ["Amazing!", "Great!", "Marvelous!", "Super!", "Excellent!", "Fantastic!", "Brilliant!", "Awesome!"];
  
  const resetTimer = () => {
    setTime(30);  // Reset state
    setKey(prevKey => prevKey + 1); // Change key to force re-render
    setStopAnimation(false); // Restart animation
  };
    // Timer countdown effect
  useEffect(() => {
      if (showFeedback) return; // Pause timer when showing feedback
  
      const countdown = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdown);
            return 0;
          }
          return prevTime - 1;
        });

      }, 1000);
  
      return () => clearInterval(countdown);
    }, [showFeedback]);
  
  useEffect(() => {
    if (question) {
      const shuffled = shuffleObject(question.multiAnswers);
      setShuffledAnswers(shuffled);
      setShowFeedback(false);
      setCanProceed(false); 
      setTimer(3); 
      setInitialOverlayVisible(true); 

      const overlayTimeout = setTimeout(() => {
        setInitialOverlayVisible(false);
      }, 500);
      return () => clearTimeout(overlayTimeout);
    }
  }, [ question]);

  useEffect(() => {
    let countdown;
    if (showFeedback && !canProceed && !isCorrect) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            setCanProceed(true); 
            clearInterval(countdown);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else if (isCorrect) {
      setCanProceed(true);
    }

    return () => clearInterval(countdown); 
  }, [showFeedback, canProceed]);

  if (!shuffledAnswers) return null;

  const handleAnswer = (answer) => {
    if (!showFeedback && answer.answer.trim() !== "") {
      recordAnswer(answer);
      setIsCorrect(answer.correctAnswer === "true");
      setShowFeedback(true);
      setStopAnimation(true);
  
      let totalPoints = 0;
  
      if (answer.correctAnswer === "true") {
        handleClickSound();
        const basePoints = 1000;
        const timeBonus = Math.max(50, (basePoints - 50) * (timeLeft / 30) + 50);
        totalPoints = frenzy ? Math.round(timeBonus) * 10 : Math.round(timeBonus) * multiplier;
  
        updateScore(score + totalPoints);
        setCorrectStreak((prevStreak) => prevStreak + 1);
        setScoreEffect(`+${totalPoints}`);
  
        if (!frenzy) {
          if ((correctStreak + 1) % 3 === 0) {
            setMultiplier((prevMultiplier) => Math.min(5, prevMultiplier + 1));
          }
        }
  
        // Add floating text
        const randomPhrase = floatingPhrases[Math.floor(Math.random() * floatingPhrases.length)];
        const randomX = Math.random() * 60 + 20; // 10% to 90% width
        const randomY = Math.random() * 40 + 10; // 10% to 90% height
  
        const newFloatingText = {
          id: Date.now(),
          text: randomPhrase,
          x: `${randomX}%`,
          y: `${randomY}%`,
        };
  
        setFloatingTexts((prev) => [...prev, newFloatingText]);
  
        setTimeout(() => {
          setFloatingTexts((prev) => prev.filter((t) => t.id !== newFloatingText.id));
        }, 1000); // Text disappears after 1s
      } else {
        if (!frenzy) {
          handleWrongClickSound();

          setMultiplier(1);
          setCorrectStreak(0);
        }
      }
    }
  };
  const handleProceed = () => {
      setScoreEffect(null)
    if (canProceed) {
      onAnswer(isCorrect ? "true" : "false");
      setIsCorrect(null);
      setShowFeedback(false);
      setCanProceed(false);
      setTimer(3); 
      setTimeLeft(30); 
      resetTimer();
    }
  };

  const questionBoxClass = showFeedback
    ? isCorrect
      ? "question-container bg-success"
      : "question-container bg-danger"
    : "question-container";

  return (
        <div className={questionBoxClass + ' nunito'}>
          <div className='h-15'>
            <div className="timer-bar-wrapper">
              <div 
                key={key} // React will treat this as a new element
                className="timer-bar"
                style={{
                  animationDuration: `${time}s`,
                  animationPlayState: stopAnimation ? "paused" : "running" // Pause animation instead of removing it
                }}
                >
              </div> 
            </div>  
            {/* Score, Multiplier, and Mute Button Row */}
            <Container fluid>
              <Row className="align-items-center text-center ">
              <Col xs="4">
                <h2 className={`multiplier-text ${frenzy ? "frenzy-effect" : ""}`}>
                  {frenzy ? "Frenzy: x10" : `Multiplier: x ${multiplier}`}
                </h2>
              </Col>
                <Col xs="4" className="position-relative">
                <h2 className="score-text">Score: {score.toLocaleString()}</h2>
                {scoreEffect && (
                      <span
                        key={scoreEffect} // Ensure the animation triggers on change
                        className="score-effect w-100"
                      >
                        {scoreEffect}
                      </span>
                    )}
              </Col>
                <Col xs="4">
                  <button className="mute-button" onClick={() => toggleMute()}>
                    {isMuted ? "🔇" : "🔊"}
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
            <div className='h-85'>
                  <Container className="h-50 justify-content-center align-items-center" fluid>
                    {showFeedback ? (
                      <Row className="h-100 justify-content-center align-items-center">
                        <Col xs="12" className="d-flex flex-column justify-content-center align-items-center">
                          <h1 className="titan-one-regular">{isCorrect ? "Correct!" : "Incorrect!"}</h1>
                          {canProceed ? (
                            <p>Click to proceed</p>
                          ) : (
                            <p>Wait {timer} second{timer > 1 ? "s" : ""} to proceed</p>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Row className="h-100 justify-content-center align-items-center">
                        {question.image && (
                          <Col xs="12" md="6" className="d-flex justify-content-center align-items-center question-modal-image">
                            <img className="quiz-card-image" src={question.image} alt="Question Image" />
                          </Col>
                        )}
                        <Col xs="12" md="6" className="d-flex flex-column justify-content-center align-items-center">
                          <h3>{question.question}</h3>
                        </Col>
                      </Row>
                    )}
                  </Container>

                      {/* row for buttons */}
                      <Container className='h-50' fluid>
                        <Row className="h-50 text-wrap">
                          {shuffledAnswers.answer1 && (
                            <Col xs="12" sm="6" className='p-1'>
                                {showFeedback && shuffledAnswers.answer1.correctAnswer=== "false" ? null : (
                                  <button 
                                    className={`btn btn-light-blue button-size kp-question-btn ${!shuffledAnswers.answer1.answer.trim() ? 'no-hover' : ''}`} 
                                    onClick={() => handleAnswer(shuffledAnswers.answer1)}
                                  >
                                    <h3>{shuffledAnswers.answer1.answer}</h3> 
                                  </button>
                                )}
                            </Col>
                          )}
                          {shuffledAnswers.answer2 && (
                            <Col xs="12" sm="6" className='p-1'>
                            {showFeedback && shuffledAnswers.answer2.correctAnswer=== "false" ? null : (
                              <button 
                                className={`btn btn-light-red button-size kp-question-btn ${!shuffledAnswers.answer2.answer.trim() ? 'no-hover' : ''}`} 
                                onClick={() => handleAnswer(shuffledAnswers.answer2)}
                              >
                                <h3>{shuffledAnswers.answer2.answer}</h3> 
                              </button>
                            )}
                            </Col>
                          )}
                        </Row>
                        <Row className="h-50">
                          {shuffledAnswers.answer3 && (
                            <Col xs="12" sm="6" className='p-1'>
                              {showFeedback && shuffledAnswers.answer3.correctAnswer=== "false" ? null : (
                                <button 
                                  className={`btn btn-light-yellow button-size kp-question-btn ${!shuffledAnswers.answer3.answer.trim() ? 'no-hover' : ''}`} 
                                  onClick={() => handleAnswer(shuffledAnswers.answer3)}
                                >
                                  <h3>{shuffledAnswers.answer3.answer}</h3> 
                                </button>
                              )}
                            </Col>
                          )}
                          {shuffledAnswers.answer4 && (
                            <Col xs="12" sm="6" className='p-1'>
                            {showFeedback && shuffledAnswers.answer4.correctAnswer=== "false" ? null : (
                              <button 
                                className={`btn btn-light-green button-size kp-question-btn ${!shuffledAnswers.answer4.answer.trim() ? 'no-hover' : ''}`} 
                                onClick={() => handleAnswer(shuffledAnswers.answer4)}
                              >
                                <h3>{shuffledAnswers.answer4.answer }</h3> 
                              </button>
                              )}
                            </Col>
                          )}
                        </Row>
                      </Container>
            </div>
        {/* Initial invisible overlay */}
          {initialOverlayVisible && (
            <div className="invisible-overlay"></div>
          )}


        {/* Invisible Overlay for Proceeding */}
        {showFeedback && (
          <div
            className="invisible-overlay"
            style={{ cursor: canProceed ? "pointer" : "default" }}
            onClick={handleProceed}
          ></div>
        )}
            {floatingTexts.map(({ id, text, x, y }) => (
              <span
                key={id}
                className="floating-text"
                style={{
                  left: x,
                  top: y,
                  color: colors[Math.floor(Math.random() * colors.length)], // Random color
                }}
              >
                {text}
              </span>
            ))}
        </div>
  );
};

export default QuizContainer;
