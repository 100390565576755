import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import "./style.css";
import KoobloPartyQuestion from "../KoobloPartyQuestion";
import API from "../../utils/API";

const EditKoobloParty = (props) => {
    const { id } = useParams();
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        API.getQuestions(id)
            .then(res => {
                setQuestions(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const onAddQuestionClick = () => {
        const newQuestion = {
            tempId: `temp-${Date.now()}`,
            question: "",
            answer: "",
            QuestionSetId: id,
        };
        setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
    };

    const onDeleteClick = (question) => {
        if (question.id) {
            // Delete from database if it has an official ID
            API.deleteQuestion(question.id)
                .then(() => {
                    setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== question.id));
                })
                .catch(error => {
                    console.error("Failed to delete question:", error);
                });
        } else if (question.tempId) {
            // Remove from state if it's a temporary question
            setQuestions(prevQuestions => prevQuestions.filter(q => q.tempId !== question.tempId));
        }
    };

    return (
        <Container>
            {questions.map((question) => (
                <Row className="m-3" key={question.id || question.tempId}>
                    <Col lg="12" className="align-self-center">
                        <KoobloPartyQuestion
                            key={question.id || question.tempId} // Ensure unique key
                            question={question}
                            onDeleteClick={() => onDeleteClick(question)}
                        />
                    </Col>
                </Row>
            ))}

            <Row className="m-3">
                <Col lg="12" className="align-self-center">
                    <Card
                        className={"kq-card-container m-0 text-center nunito justify-content-center add-class-card"}
                        onClick={onAddQuestionClick}
                    >
                        <p className="text-center card-title">
                            <i className="fa-solid fa-plus"></i> Add Question
                        </p>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default EditKoobloParty;
