import React, { useEffect, useState } from 'react';
import './style.css';

const colors = ['green', 'blue', 'purple', 'orange', 'red', 'rainbow'];

const MultiplierBorder = ({ multiplier, children }) => {
  const [borderColor, setBorderColor] = useState(colors[multiplier-1]);
  
  // useEffect(() => {
  //   let index = 0;
  //   const interval = setInterval(() => {
  //     index = (index + 1) % colors.length;
  //     setBorderColor(colors[index]);
  //   }, 500); // Change color every 0.5 seconds

  //   return () => clearInterval(interval);
  // }, []);
  useEffect(() => {
    setBorderColor(colors[multiplier-1])
  }, [multiplier]);

  return (
    <div className={`multiplier-border border-${borderColor} multiplier-${multiplier}`}>
      {children} {/* Renders whatever is inside */}
    </div>
  );
};

export default MultiplierBorder;
