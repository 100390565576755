import { Container, Row, Col} from 'reactstrap';
import './style.css';
import { useState, useEffect,useRef } from "react";
import {Footer} from "../../components/Footer"
import MildlyInfuriatedBird from '../../assets/images/MildlyInfuriatedBird.png';
import basketballPreview from '../../assets/images/basketball.PNG';
import tictactoePreview from '../../assets/images/tictactoe.png';
import jeopardyPreview from '../../assets/images/jeopardy.png';
import treasurehunt from '../../assets/images/treasurehunt.png';
import koobloEditor from '../../assets/images/koobloEditor.PNG';
import multiChoiceEdit from '../../assets/images/multi-choice-edit.png';
import createScreen from '../../assets/images/create-screen.png';
import gamePreview from '../../assets/images/game-preview.png';
import HungryHeroPreview from '../../assets/images/HungryHeroPreview.png';
import HungryHero2Preview from '../../assets/images/HungryHero2Preview.png';
import koobloClassicPreview from '../../assets/images/kooblo-classic-preview.png';

const images = [
    koobloClassicPreview,
    MildlyInfuriatedBird,
    basketballPreview,
    tictactoePreview,
    jeopardyPreview,
    treasurehunt,
    HungryHeroPreview,
    HungryHero2Preview,
  ];
  
const Main = () => {    
    
    const [inView, setInView] = useState({});
      
    const observer = useRef(
      new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setInView(prev => ({ ...prev, [entry.target.id]: true }));
            }
          });
        },
        { threshold: 0.1 }
      )
    );
  
    useEffect(() => {
      const elements = document.querySelectorAll('.feature-col');
      elements.forEach(el => observer.current.observe(el));
      return () => elements.forEach(el => observer.current.unobserve(el));
    }, []);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
        return (
            <>
            <div className="main-background" >
                {/* HERO SECTION */}
                <section id="hero">
                    <div className="hero-section vertical-center">
                        <Container>                            <Row>
                                <Col xs="12" className='text-center titan-one-regular hero-content'>
                                <h1 className=' hero-section-h1'>Empower Your Classroom</h1>
                                <h3 className='nunito'>Create customizable question sets and play engaging games with your students.</h3>
                                <a className="btn btn-rounded nav-btn-light-blue navbar-btn" href="/login">Login</a>
                                <a className="btn btn-rounded nav-btn-light-purple navbar-btn" href="/signup">Sign Up</a>
                                </Col>
                            </Row>
                            <Row>
                            <Col xl="12" className='text-center'>
                                <img src={MildlyInfuriatedBird} className='hero-secion-img'></img>
                            </Col>

                            </Row>
                        </Container>
                    </div>
                </section>
            {/* HERO SECTION end*/}

                {/* FEATURES SECTION */}

                <section id="features">
                    <div className="features-section vertical-center" style={{ background: 'rgba(149,0,254,1)' }}>
                    <div className="custom-shape-divider-top-1722067353">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                    </div>
                        <Container className="justify-content-center align-items-center nunito" style={{ marginTop: '50px' }}>
                        {/* Row 1 */}
                        <Row className="align-items-center">
                            <Col xl="6" className={`feature-col text-center ${inView['feature1'] ? 'slide-in-left' : 'hidden'}`} id="feature1">
                            <h3>Fully Customizable</h3>
                            <p>Craft unique question sets tailored to your curriculum, allowing you to engage students with content that aligns perfectly with your teaching goals...</p>
                            </Col>
                            <Col xl="6" className={`feature-col text-center ${inView['feature1'] ? 'slide-in-right' : 'hidden'}`} id="feature1">
                            <img className='feature-image' src={koobloEditor} alt="Customizable Feature" />
                            </Col>
                        </Row>

                        {/* Row 2 */}
                        <Row className="align-items-center">
                            {isMobile ? (
                            <>
                                <Col xl="6" className={`feature-col text-center ${inView['feature2'] ? 'slide-in-left' : 'hidden'}`} id="feature2">
                                <h3>Variety of Game Formats</h3>
                                <p>Explore a diverse range of game formats designed to enhance learning through play. From Jeopardy-style quizzes to collaborative team challenges...</p>
                                </Col>
                                <Col xl="6" className={`feature-col text-center ${inView['feature2'] ? 'slide-in-right' : 'hidden'}`} id="feature2">
                                <img className='feature-image' src={treasurehunt} alt="Game Formats Feature" />
                                </Col>
                            </>
                            ) : (
                            <>
                                <Col xl="6" className={`feature-col text-center ${inView['feature2'] ? 'slide-in-left' : 'hidden'}`} id="feature2">
                                <img className='feature-image' src={treasurehunt} alt="Game Formats Feature" />
                                </Col>
                                <Col xl="6" className={`feature-col text-center ${inView['feature2'] ? 'slide-in-right' : 'hidden'}`} id="feature2">
                                <h3>Variety of Game Formats</h3>
                                <p>Explore a diverse range of game formats designed to enhance learning through play. From Jeopardy-style quizzes to collaborative team challenges...</p>
                                </Col>
                            </>
                            )}
                        </Row>

                        {/* Row 3 */}
                        <Row className="align-items-center">
                            {/* <Col xl="6" className={`feature-col text-center ${inView['feature3'] ? 'slide-in-left' : 'hidden'}`} id="feature3">
                            <h3>Easy to Use</h3>
                            <p>Designed with educators in mind, our platform is easy to navigate and use. Spend less time on setup and more time teaching...</p>
                            </Col>
                            <Col xl="6" className={`feature-col text-center ${inView['feature3'] ? 'slide-in-right' : 'hidden'}`} id="feature3">
                            <img className='feature-image' src="https://t4.ftcdn.net/jpg/00/88/68/17/360_F_88681735_DGekSlvZg1keWVRNtDinXmwERnJwokEs.jpg" alt="Easy to Use Feature" />
                            </Col> */}
                            <Col xl="6" className={`feature-col text-center ${inView['feature4'] ? 'slide-in-left' : 'hidden'}`} id="feature4">
                                <h3>Engage and Motivate Students</h3>
                                <p>Boost student participation and enthusiasm with games that motivate and excite. Our engaging formats are perfect for encouraging collaboration...</p>
                                </Col>
                                <Col xl="6" className={`feature-col text-center ${inView['feature4'] ? 'slide-in-right' : 'hidden'}`} id="feature4">
                                <img className='feature-image' src="https://images.pexels.com/photos/8423046/pexels-photo-8423046.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Engage Students Feature" />
                            </Col>

                        </Row>

                        {/* Row 4 */}
                        {/* <Row className="align-items-center">
                            {isMobile ? (
                            <>
                                <Col xl="6" className={`feature-col text-center ${inView['feature4'] ? 'slide-in-left' : 'hidden'}`} id="feature4">
                                <h3>Engage and Motivate Students</h3>
                                <p>Boost student participation and enthusiasm with games that motivate and excite. Our engaging formats are perfect for encouraging collaboration...</p>
                                </Col>
                                <Col xl="6" className={`feature-col text-center ${inView['feature4'] ? 'slide-in-right' : 'hidden'}`} id="feature4">
                                <img className='feature-image' src="https://images.pexels.com/photos/8423046/pexels-photo-8423046.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Engage Students Feature" />
                                </Col>
                            </>
                            ) : (
                            <>
                                <Col xl="6" className={`feature-col text-center ${inView['feature4'] ? 'slide-in-left' : 'hidden'}`} id="feature4">
                                <img className='feature-image' src="https://t4.ftcdn.net/jpg/00/88/68/17/360_F_88681735_DGekSlvZg1keWVRNtDinXmwERnJwokEs.jpg" alt="Engage Students Feature" />
                                </Col>
                                <Col xl="6" className={`feature-col text-center ${inView['feature4'] ? 'slide-in-right' : 'hidden'}`} id="feature4">
                                <h3>Engage and Motivate Students</h3>
                                <p>Boost student participation and enthusiasm with games that motivate and excite. Our engaging formats are perfect for encouraging collaboration...</p>
                                </Col>
                            </>
                            )}
                        </Row> */}
                        </Container>
                    </div>
                    </section>
                {/* FEATURES SECTION END*/}

                
                 {/* HOW IT WORKS SECTION */}
                 <section id="howitworks" style={{padding:"0px"}}>
                    <div className=' how-it-works-section'>
                    <Container fluid>
                            <Row className='' >
                                <div className="custom-shape-divider-top-1726411357">
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                                </svg>
                            </div>

                                <Col xl="4" className='text-center nunito' style={{ paddingTop: '20px' }}>
                                    <div className='how-it-works-step'>
                                    <p className='step-title'>Create</p>
                                    <img src={createScreen} alt="Sign Up" className='step-image'/>
                                    <p className='step-description'>
                                    Choose the game format that suits your needs—whether it's teacher-led or student-driven, we've got options for every classroom dynamic.</p>
                                    </div>
                                </Col>
                                <Col xl="4" className='text-center nunito' style={{ paddingTop: '20px' }}>
                                    <div className='how-it-works-step'>
                                    <p className='step-title'>Add Questions</p>
                                    <img src={multiChoiceEdit} alt="Create" className='step-image'/>
                                    <p className='step-description'>
                                    Add questions quickly and easily using our intuitive interface.
                                    </p>
                                    </div>
                                </Col>
                                <Col xl="4" className='text-center nunito' style={{ paddingTop: '20px' }}>
                                    <div className='how-it-works-step'>
                                    <p className='step-title'>Play</p>
                                    <img src={gamePreview} alt="Play" className='step-image'/>
                                    <p className='step-description'>
                                    Choose from a variety of games to play with your question set, enhancing engagement and making learning enjoyable and interactive.
                                    </p>
                                    </div>
                                </Col>
                                </Row>
                                <div className="custom-shape-divider-bottom-1726413970">
                                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                                    </svg>
                                </div>
                    </Container>
                    </div>
                </section>

                {/* Plan options sections */}
                <section id="subscriptions" style={{padding:"0px"}}>
                    <div className='banner-wrapper'>
                    <div className="static-banner">
                    {images.map((image, index) => (
                        <div className="image-container" key={index}>
                        <img src={image} alt={`Slide ${index}`} className="banner-image" />
                        </div>
                    ))}
                </div>

                    </div>

                    {/* <div className=' subscriptions-section container-fluid'>
                        <Row className='justify-content-center'>
                        <Col xl="4" className='text-center nunito' style={{ padding: '20px' }}>
                            <div className='how-it-works-step'>
                            <p className='step-title'>Sign Up</p>
                            <img src="https://t4.ftcdn.net/jpg/00/88/68/17/360_F_88681735_DGekSlvZg1keWVRNtDinXmwERnJwokEs.jpg" alt="Sign Up" className='step-image'/>
                            <p className='step-description'>
                                Register for an account to get started. It’s quick and easy to sign up and get access to all features.
                            </p>
                            </div>
                        </Col>
                        <Col xl="4" className='text-center nunito' style={{ padding: '20px' }}>
                            <div className='how-it-works-step'>
                            <p className='step-title'>Create</p>
                            <img src="https://t4.ftcdn.net/jpg/00/88/68/17/360_F_88681735_DGekSlvZg1keWVRNtDinXmwERnJwokEs.jpg" alt="Create" className='step-image'/>
                            <p className='step-description'>
                                Craft your unique content using our intuitive tools. Customize quizzes, polls, and games effortlessly.
                            </p>
                            </div>
                        </Col>
                        <Col xl="4" className='text-center nunito' style={{ padding: '20px' }}>
                            <div className='how-it-works-step'>
                            <p className='step-title'>Play</p>
                            <img src="https://t4.ftcdn.net/jpg/00/88/68/17/360_F_88681735_DGekSlvZg1keWVRNtDinXmwERnJwokEs.jpg" alt="Play" className='step-image'/>
                            <p className='step-description'>
                                Engage with your content and have fun! Use our platform to interact, learn, and make education exciting.
                            </p>
                            </div>
                        </Col>
                        </Row>
                    </div> */}
                </section>

            </div>
            <Footer/>
            </>
       );     
    
};

export default Main; 